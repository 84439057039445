import React from 'react'
import { Section } from '../components/section'
import Layout from '../components/layout'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

const TermsPage = () => (
  <Layout>
    <Header external={true} />
    <Section theme="light" legacy>
      <h1 id="allmäna-användarvillkor">ALLMÄNA ANVÄNDARVILLKOR HEALFY</h1>
      <p>
        Dessa avtalsvillkor (”<strong>Användarvillkoren</strong>”) reglerar din användning av Healfy (hädanefter “<strong>Tjänsten</strong>
        ”, så som definierat nedan). Genom att du godkänner Användarvillkoren ingås ett avtal mellan dig (”<strong>du</strong>” eller ”
        <strong>dig</strong>”) och Nordic Health Innovation AB, registreringsnummer 556878–3921 med adress Norrberg Hjalmarslund, 923 98
        Storuman, Sverige (”
        <strong>vi</strong>” eller ”<strong>oss</strong>”).
      </p>
      <p>
        Tjänsten tillhandahålls via vår mobila applikation (”<strong>Appen</strong>”) och vår hemsida{' '}
        <a href="https://www.healfy.se">www.healfy.se</a> (”<strong>Hemsidan</strong>”). Genom att du använder Tjänsten accepterar du dessa
        Användarvillkor. För det fall du inte accepterar dessa Användarvillkor, ber vi dig att inte använda Tjänsten som vi tillhandahåller.
      </p>
      <p>Dessa Användarvillkor gäller i Sverige.</p>
      <h3>TJÄNSTEN</h3>
      <p>
        Healfy är en molnbaserad plattform som erbjuds i tre olika versioner och möjliggör trygg lagring av din hälsodata samt digitala
        hälsoundersökningar mellan dig och en eller flera vårdgivare. Med hjälp av Tjänsten kan en användare genomföra mätningar av
        exempelvis blodtryck, vikt, blodsocker och blodvärde för att sedan skicka dessa uppgifter vidare digitalt till en eller flera
        vårdgivare. Tjänsten möjliggör därutöver kommunikation mellan användare och vårdgivare samt att vårdgivare kan välja att integrera
        användardata i befintliga verksamhetssystem.
      </p>
      <p>
        Vänligen notera att Healfy ej är en vårdgivare i något avseende utan enbart utgör en teknisk lösning. Dessa Användarvillkor avser
        användandet av Tjänsten i egenskap av teknisk lösning och Healfy bär under inga omständigheter ansvar hänförligt till din data eller
        kommunikationen mellan dig och vårdgivaren. Vid akuta fall eller livshotande tillstånd avråder vi starkt från att använda Tjänsten
        och rekommenderar istället att ringa 112 eller tillämpligt nödnummer, utan att det föregående på något vis ska uppfattas som
        medicinsk rådgivning från oss.
      </p>
      <p>
        Alla som använder Tjänsten beskrivs hädanefter i dessa Användarvillkor som (”<strong>Användare</strong>”), vilket även inbegriper
        dig som part till avtalet som du ingår med oss genom att acceptera dessa Användarvillkor.
      </p>
      <p>
        Eftersom Tjänsten bl.a. syftar till att möjliggöra för Användare och tredje man att ingå avtal och andra rättshandlingar med
        varandra, vill vi framhålla att vi inte på något sätt är att betrakta som avtalspart, mellanhand, agent, kommissionär eller på annat
        sätt kopplad till våra Användares mellanhavanden och användning av Tjänsten, annat än genom dessa Användarvillkor som ingås med
        varje Användare enskilt.
      </p>
      <h3>LICENS</h3>
      <p>
        Under förutsättning att du accepterar och följer dessa Användarvillkor, erhåller du en icke-exklusiv, icke överlåtbar, återkallelig
        licens att ladda ned, installera och använda Tjänsten i objektkodform på en mobil enhet eller via en dator som du äger eller har
        tillgång till, i Sverige, i syfte att nyttja Tjänsten för dess avsedda syfte.
      </p>
      <p>
        Du erhåller ingen annan licens att använda ovan nämnda immateriella rättigheter utom vad som uttryckligen anges i dessa
        Användarvillkor.
      </p>
      <p>
        Du förstår och accepterar att innehåll som publiceras inom Tjänsten kan tillhöra tredje parter, samt att vi inte har kontroll över
        sådant innehåll. Därför förstår och accepterar du att vi inte kan hållas ansvariga för sådant innehåll som tillhandahålls av tredje
        part och visas upp eller finns tillgängligt inom ramen för Tjänsten.
      </p>
      <p>
        Vi ger varken dig eller någon annan tillstånd att kopiera eller förändra Tjänsten i sin helhet eller delar av den. Detta innebär att
        ingen utan vårt samtycke får utveckla, göra tillägg till, dekompilera eller göra reverse engineering på Tjänsten eller dess
        komponenter. Det är inte tillåtet att återskapa källkoden eller dess funktionalitet, eller att göra kopior av mjukvaran, utöver vad
        tvingande lag ger rätt till.
      </p>
      <p>
        Du får heller inte använda Tjänsten för att sprida virus, trojaner eller liknande program. Vi tillåter inte automatisk avläsning av
        Tjänsten.
      </p>
      <h3>PERSONUPPGIFTSBEHANDLING</h3>
      <p>
        När du använder Tjänsten kommer du att ombedjas att direkt och indirekt delge viss information om dig själv. Vänligen läs vår
        Integritetspolicy, som du kan hitta här <a href="https://healfy.se/privacy-policy">https://healfy.se/privacy-policy</a> innan du
        börjar använda Tjänsten och använd inte Tjänsten om du inte accepterar Integritetspolicyn. Vänligen notera att vi ej är
        personuppgiftsansvariga för personuppgifter som du registrerar och lagrar i Tjänsten eller för personuppgifter som du delar med en
        vårdgivare i Tjänsten. I det senare fallet är är vårdgivaren enligt lag personuppgiftsansvarig och vi ett personuppgiftsbiträde åt
        vårdgivaren.
      </p>
      <p>
        Om du använder en tjänst från tredje part för att logga in på ditt Användarkonto hos oss, till exempel BankID, ger du oss rätt att
        inhämta din verifieringsinformation, så som användarnamn och krypterade inloggningsuppgifter, och annan information som kan vara av
        intresse att överföra från den andra tjänsten. All sådan överförd information kommer att behandlas i enlighet med vår
        Integritetspolicy, och finnas beskriven där.
      </p>
      <h3>DIN ANVÄNDNING AV TJÄNSTEN</h3>
      <p>
        Vi vill inte att Tjänsten ska användas för något annat än dess avsedda syfte. Din användning av Tjänsten får bara ske i enlighet med
        dess avsedda syfte, som beskrivits ovan. Om du inte accepterar detta, förbehåller vi oss rätten att hindra dig tillgång till
        Tjänsten.
      </p>
      <h4>Meddelanden</h4>
      <p>
        Du är ansvarig för samtliga meddelanden som du sänder vid användande av Tjänsten och du garanterar att du inte kommer skicka
        meddelanden som strider mot dessa Användarvillkor.
      </p>
      <p>Inom dessa Användarvillkor tillämpar vi samma regler och hållning för meddelanden som för annat Användargenererat material.</p>
      <h4>Användargenererat Material</h4>
      <p>
        I Tjänsten kan du ladda upp innehåll såsom hälsodata, skriftliga kommentarer, meddelanden samt fotografier och video (”
        <strong>Användargenererat Material</strong>”).
      </p>
      <ol type="a">
        Du accepterar och garanterar att du inte kommer att ladda upp eller sprida Användargenererat Material inom Tjänsten som:
        <li>är oriktigt, vilseledande, osant eller inkorrekt,</li>
        <li>främjar eller uppmuntrar illegal aktivitet,</li>
        <li>är rasistiskt eller etniskt kränkande och/eller utgör agitation mot en minoritet (så som en nationell eller etnisk grupp),</li>
        <li>utgör ärekränkning, innehåller pornografi eller är på annat sätt sexuellt kränkande,</li>
        <li>utgör angrepp på sexuell läggning eller religion eller är diskriminerande på annat sätt,</li>
        <li>utgör förolämpning mot eller förföljelse av en person,</li>
        <li>
          på något sätt är skadligt, kränkande, stötande eller illegalt eller som gör intrång i tredje parts rättighet (inklusive men ej
          begränsat till upphovsrätt och varumärken), eller
        </li>
        <li>
          i övrigt strider mot Tjänstens avsedda syften (inklusive men ej begränsat till andra sorters personuppgifter än vad som är avsett
          att laddas upp i Tjänsten).
        </li>
      </ol>
      <h4>Vår Hållning avseende Användargenererat Material</h4>
      <p>
        Vi tolererar inte budskap som vi anser vara olämpliga, olagliga eller oetiska. Vi har en lagstadgad skyldighet att övervaka vilket
        Användargenererat Material som tillgängliggörs i Tjänsten och att under vissa förutsättningar ta bort Användargenererat Material
        från Tjänsten. Vi förbehåller oss rätten att, efter eget godtycke, ta bort Användargenererat Material som vi bedömer oförenligt med
        dessa Användarvillkor eller som vi på annat sätt anser är otillbörligt, oetiskt eller olagligt eller kan vara skadligt för oss eller
        Tjänstens Användare.
      </p>
      <p>
        Du garanterar att du innehar samtliga nödvändiga rättigheter för att visa och ladda upp Användargenererat Material, att använda
        Användargenererat Material på övriga sätt och att bevilja oss licens till Användargenererat Material enligt ovan.
      </p>
      <h4>Nätverkskostnader</h4>
      <p>Ersättning för Tjänsten utgår i enlighet med vår, från tid till annan, gällande prislista.</p>
      <h3>ANSVARSBEGRÄNSNING</h3>
      <p>
        Tjänsten, inklusive Hemsidan och Appen, tillhandahålls i befintligt skick utan garantier av något slag. Din användning av Tjänsten
        är enbart ditt eget ansvar och sker på din egen risk. Vi lämnar inte några garantier - direkta, underförstådda eller på annat sätt -
        rörande tillgängligheten, kvalitén, dugligheten för något särskilt syfte, lämpligheten eller riktigheten av Hemsidan, Appen eller
        Tjänsten i övrigt.
      </p>
      <p>
        Du är införstådd med att det kan förekomma situationer då Tjänsten inte kommer att vara tillgänglig, på grund av men inte begränsat
        till, underhåll och omständigheter utanför vår kontroll. Därutöver förbehåller vi oss rätten att i framtiden ändra eller upphöra med
        tillhandahållandet av Tjänsten, efter vårt eget gottfinnande. I den utsträckning som tillåts under tvingande lagstiftning ansvarar
        vi inte gentemot dig eller någon tredje part för någon direkt, indirekt eller någon annan skada av något slag inklusive, men ej
        begränsat till, utebliven vinst, inkomstförlust, minskad omsättning, avbrott i verksamheten eller goodwillförluster som uppkommer på
        grund av eller i samband med dessa Användarvillkor eller oförmåga att använda Tjänsten. Vi är inte ansvariga gentemot dig för några
        tredjepartskrav som riktas mot dig. Under inga omständigheter ska vårt totala ansvar gentemot dig i samband med Tjänsten för direkt
        skada överskrida den ersättning vi erhållit från dig, i enlighet med vår från tid till annan gällande prislista, de 12 föregående
        månaderna omedelbart fram till att skadehändelsen inträffade
      </p>
      <h3>SKADESLÖSHETSFÖRBINDELSE</h3>
      <p>
        Du är ansvarig för all skada som åsamkas oss, eller tredje part, på grund av ditt brott mot dessa Användarvillkor, inklusive men ej
        begränsat till missbruk av Hemsidan, Appen och/eller Tjänsten. Vidare åtar du dig att hålla oss skadeslösa i relation till samtliga
        krav, kostnader (inklusive skäliga legala kostnader), skador, utlägg, skadestånd och förluster som vi åsamkats på något sätt på
        grund av dina brott mot dessa Användarvillkor eller annan tillämplig lag.
      </p>
      <p>
        För det fall något Användargenererat Material gör intrång i tredje parts immateriella rättighet eller tillämplig
        dataskyddslagstiftning samtycker du till att omedelbart ta bort samtliga intrångsgörande delar av materialet och hålla oss
        skadeslösa för samtliga skador, kostnader och utlägg vi ådragit oss som resultat av sådant intrång.
      </p>
      <h3>ÄNDRING AV VILLKOREN SAMT ÄNDRING OCH UPPSÄGNING AV TJÄNSTEN</h3>
      <p>
        Vi har rätt att ändra dessa Användarvillkor. Vi kommer att informera dig om sådana ändringar senast trettio (30) dagar innan en
        ändring träder i kraft. Vi kommer att lämna dig sådan information här{' '}
        <a href="https://healfy.se/general-terms">https://healfy.se/general-terms</a> eller via e-post till den e-postadress som
        tillhandahållits av dig.
      </p>
      <p>
        Du har rätt att när som helst och utan föregående meddelande härom säga upp ditt Användarkonto och ta bort ditt innehåll från
        Tjänsten.
      </p>
      <p>
        Vi har rätt att stänga av din tillgång till Tjänsten med omedelbar verkan om vi har anledning att tro att du bryter mot dessa
        Användarvillkor. Vidare förbehåller vi oss rätten att, utefter eget godtycke, när som helst modifiera, avbryta eller temporärt eller
        permanent upphöra med vårt tillhandahållande av Tjänsten utan föregående meddelande härom, eller om så krävs enligt lag eller
        myndighetsbeslut. Du accepterar att vi inte ansvarar gentemot dig eller någon tredje part för sådan modifikation, avbrott eller
        upphörande.
      </p>
      <h3>ÖVERLÅTELSE</h3>
      <p>
        Du får inte överlåta eller överföra några rättigheter, skyldigheter eller licenser som framgår av dessa Användarvillkor. Vi kan
        överlåta och överföra våra rättigheter enligt dessa Användarvillkor utan ditt samtycke och utan meddelande till dig.
      </p>
      <h3>TILLÄMPLIG LAG OCH TVISTER</h3>
      <p>Dessa Användarvillkor ska styras av och tolkas i enlighet med svensk lag, utan tillämpning av lagvalsregler.</p>
      <p>
        Tvist eller krav som uppstår rörande eller i samband med dessa Användarvillkor, eller vid brott, uppsägning eller ogiltighet av
        dessa ska slutligen avgöras av svensk domstol, med Stockholms tingsrätt som första instans, om inte annat följer av tvingande lag.
      </p>
      <div>
        <h3>KONTAKTUPPGIFTER</h3>
        <p>
          Nordic Health Innovation AB
          <br />
          556878-3921l
          <br />
          Storgatan 20, 852 30 Sundsvall
          <br />
          <a href="mailto:info@nhiab.com">info@nhiab.com</a>
        </p>
      </div>
    </Section>
    <Footer theme="dark" page="terms" />
  </Layout>
)

export default TermsPage
